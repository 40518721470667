import * as React from 'react'

export const ArrowTop = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="24"
      height="24"
      transform="matrix(-1 0 0 1 24 0)"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.59801 11.5986L6.49902 10.4996L11.9986 5L17.4981 10.4996L16.3982 11.5995L12.7763 7.97756L12.7763 19L11.2208 19L11.2208 7.97575L7.59801 11.5986Z"
      fill="#333333"
    />
  </svg>
)

export default ArrowTop
